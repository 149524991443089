@import './variables';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css?family=Share+Tech&display=swap');
@import url('https://fonts.googleapis.com/css?family=Baloo+Thambi+2&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Electrolize&display=swap');
@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');


body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black !important;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin: 0;
  padding: 0;

  @media only screen and (max-width: 700px) {
    h2 {
      font-size: 1.5em;
    }
  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// h2 {
//   font-family: 'Press Start 2P', cursive;
//   color: limegreen;
// }

// a:link {
//   color: rgb(0, 255, 115);
// }

// a:visited {
//   color: rgb(0, 255, 115);
// }

// a:hover {
//   color: yellowgreen;
// }

// a:active {
//   color: yellow;
// }
